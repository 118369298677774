.nav-link {
    margin: 60px;
    margin-top: 50px;
}

.navbar-brand img {
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.navbar-brand {
    padding-top: 0.9rem;
    padding-bottom: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.capa img {
    width: 100%;
    max-width: 1920px;
}

.nav-link {
    color: black;
    font-size: 20px;
}

.navbar {
    padding: 0;
}

.section3 {
    background-color: #EAE0DA;
    text-align: center;
    padding: 0px;
}

.section5 {
    background-color: #EAE0DA;
}

.section3 img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    cursor: pointer;
}

.container3 {
    transform: translateY(100px);
}

.title2 {
    margin-bottom: 5%;
}

.section3 img:hover {
    filter: brightness(70%);
    transition: all 450ms ease-in-out;
}

.section4 h2,
.section3 h1 {
    color: #A26B20;
    font-family: 'Playfair Display', serif;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 45px;
    text-decoration: underline 1px;
    text-underline-position: under;

}

.section3 p {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    color: #645E5E;
    font-family: 'Montserrat', sans-serif;
}

.section4 p {
    font-size: 25px;
    text-align: justify;
    color: #645E5E;
    font-family: 'Montserrat', sans-serif;
    width: 75%;
    font-style: normal;
    font-weight: 300;
    line-height: 45px;
}

.section4 {
    margin-top: 10rem;
    margin-bottom: 5rem;
}

.section4 img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.section5 {
    margin-bottom: 50px;
}

.section5 h3 {
    color: #A26B20;
    font-family: 'Playfair Display', serif;
    margin-bottom: 30px;
    text-decoration: underline 1px;
    text-underline-position: under;
    font-weight: 500;
    text-align: center;
    font-size: 35px;
}

.section5 p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #645E5E;
    font-family: 'Montserrat', sans-serif;
}

.section6 h2 {
    color: #A26B20;
    font-family: 'Playfair Display', serif;
    margin-bottom: 50px;
    text-decoration: underline 1px;
    text-underline-position: under;
    font-weight: 500;
    font-size: 50px;
    text-align: center;
}

.hover {
    margin-top: 80px;
}

.hover a {
    display: block;
    text-decoration: none;
    color: #A26B20;
}

.card-link {
    margin: 0px !important;
    margin-left: 1rem !important;
}

.form span,
.section7 span {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.form h5,
.section7 h5 {
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #575353;
}

.form h4,
.section7 h4 {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 500;
    font-size: 40px;
    color: #001A44;
    text-underline-position: under;
    text-align: center;
    margin-bottom: 40px;
    text-decoration: underline 2px;
}

.contacts {
    display: grid;
    place-items: center;
}

.agendar {
    background: #CFB28B;
    border-radius: 6.44444px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #001A44;
    text-decoration: none;
    padding: 3px 20px;
    margin-top: 30px;
    text-align: center;
}

.enviar {
    background: #CFB28B;
    border-radius: 6.44444px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #001A44;
    text-decoration: none;
    padding: 3px 20px;
    float: right;
    text-align: center;
    border: none;
}

.form-control {
    margin: 3px !important;
    border: 2px solid #001A44;
    outline: none !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.section7 .form-check {
    margin-left: 0.3rem;
}

.section7 form {
    width: 80%;
    margin-right: auto;
}

.rodape {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #EAE0DA;
    display: grid;
    place-items: center;
}

.rodape svg {
    margin: 10px;
    margin-bottom: 30px;
    fill: #001A44;
}

.terms a {
    margin: 5px;
    color: #001A44;
    font-family: 'Montserrat';
    font-weight: 600;
    text-decoration: none;
}

.rodape p {
    margin: 5px;
    color: #001A44;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
}
.btnWpp{
    position: fixed;
    right: 50px;
    bottom: 50px;
}