body {
  min-width: 450px;
}

.headerBolos {
  background: linear-gradient(180deg, #eae0da 0%, rgba(234, 224, 218, 0) 100%);
}

.headerBolos h2 {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 500;
  font-size: 45px;
  text-align: left;
  color: #001a44;
}

.card-img-top {
  height: 300px;
}
.headerBolos .subTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: lighter;
  font-size: 40px;
  text-align: left;
  color: #001a44;
}

.headerBolos .textoCard {
  font-family: "Montserrat";
  font-size: 20px;
  color: #001a44;
  margin-left: 5px;
  text-transform: capitalize;
}

.headerBolos .baixar {
  background: #cfb28b;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #001a44;
  text-align: center;
  text-decoration: none;
  padding: 2px 50px;
  margin-left: 17%;
}

.headerBolos .title {
  margin-left: 20%;
}

.headerBolos h1 {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 500;
  font-size: 40px;
  color: #001a44;
  text-align: center;
  margin: 50px;
  text-decoration: underline 2px;
  text-underline-position: under;
}

.headerBolos .card {
  margin: 30px;
}

.headerBolos .card img {
  width: 100%;
}

.headerBolos .card-body {
  background-color: #eae0da;
}

.headerBolos .card-body a {
  float: right;
  background-color: #cfb28b;
  border-radius: 5px;
  padding: 2px 10px;
  color: white !important;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none;
}

.headerBolos .card-body p {
  font-size: 15px;
}

@media (max-width: 1230px) {
  .headerBolos .title {
    margin-left: 0%;
  }

  .headerBolos h2 {
    margin-top: 20px;
    white-space: nowrap;
    font-size: 35px;
  }

  .headerBolos .subTitle {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .headerBolos .baixar {
    margin-left: 30%;
  }

  .headerBolos .title {
    margin-left: 0%;
  }

  .headerBolos h2 {
    margin-top: 20px;
    font-size: 35px;
    text-align: center;
  }

  .headerBolos .subTitle {
    font-size: 25px;
    text-align: center;
  }
}
