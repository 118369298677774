body {
    min-width: 450px;
}

.headerDecoracao {
    background: linear-gradient(180deg, #EAE0DA 0%, rgba(234, 224, 218, 0) 100%);
}

.headerDecoracao h1 {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 500;
    font-size: 45px;
    white-space: nowrap;
    padding-top: 100px;
    margin-bottom: 50px;
    text-decoration: underline 2px;
    text-underline-position: under;
    text-align: center;
    color: #001A44;
}

.gridBolos{
    width: 100%;
}