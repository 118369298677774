@media (max-width: 768px) {
    .desktopNav {
        display: none;
    }
    .headerDecoracao h1 {
        font-size: 35px !important;
    }
    .nav-link {
        margin: 0px;
        margin-top: 0px;
    }
    .capa .mobile{
        display: block;
    }
    .capa .desktop{
        display: none;
    }

    .navbar-brand img {
        width: 100px;

    }

    .section2 img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .section3 img {
        margin: 10px;
    }

    header {
        padding-bottom: 0px;
    }

    .container3 {
        transform: translateY(0px);
    }

    .section3 {
        background-color: #EAE0DA;
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .section4 {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .section4 p {
        font-size: 20px;
        text-align: justify;
        color: #645E5E;
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
    }

    .section4 h2,
    .section3 h1 {
        margin-top: 30px;
        text-align: center;

    }

    .section7 form {
        width: 100%;
        margin-top: 30px;
    }

    section {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

}

@media (max-width: 1024px) {
    .desktopNav {
        display: none;
    }

    .nav-link {
        margin: 0px;
        margin-top: 0px;
    }

    .navbar-brand img {
        width: 100px;

    }

    .section4 p {
        font-size: 20px;
        text-align: justify;
        color: #645E5E;
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        font-style: normal;
        font-weight: 300;
        line-height: 25px;
    }

}

@media (min-width: 1024px) {
    .desktopNav {
        display: block;
    }

    .mobileNav {
        display: none;
    }

}