@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Playfair+Display:ital,wght@1,500&display=swap');

body{
    font-family: 'Montserrat', sans-serif;
    min-width: 450px;
}

section{
    padding-top: 4rem;
    padding-bottom: 4rem;
}

section img{
    image-rendering: optimizeQuality;
}
.rodape a{
    text-decoration: none;
    color: #001A44;
}
.dev{
    padding-top: 1rem;
}

.capa .mobile{
    display: none;
}

.slick-prev:before {
    color: black !important;
  }
  .slick-next:before {
    color: black !important;
  }
  .card{
      border: none !important;
  }
  textarea{
      resize: none !important;
  }
  a:hover{
    color: #001A44 !important;
  }
  svg:hover{
      transform: scale(1.05);
  }
  svg{
      cursor: pointer;
  }
  .col {
    padding: 3px !important;
}
img{
    image-rendering: -webkit-optimize-contrast;
}